<!-- eslint-disable no-console -->
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import api from "../../../../common/axios";

/**
 * Advanced table component
 */
export default {
  page: {
    title: "Tambah Industri",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      config: {
        redirect: "industri",
        api: "industry",
        upload: "media",
      },
      title: "Tambah Industri",
      items: [
        {
          text: "Industri",
        },
        {
          text: "Tambah Industri",
          active: true,
        },
      ],
      status: [
        { text: "Aktif", value: "aktif" },
        { text: "Tidak Aktif", value: "tidak aktif" },
      ],
      model: {
        name: null,
        status: null,
        photo: null,
      },
      submitform: false,
      routeBase: "industri",
      profile_picture: null,
      show: false,
      responses: {
        message: null,
      },
    };
  },
  mounted() {
    let _ = this;
    _.getData();
  },
  methods: {
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        api.get(_.config.api + "/" + this.$route.params.id).then((response) => {
          let _ = this;
          _.model = response.data.data;
          _.profile_picture = _.model.photo;
        });
      }
    },
    save() {
      let _ = this;
      // if (_.model.photo == null || _.model.photo == "") {
      //   _.show = true;
      //   return;
      // }
      _.model.photo = '-';
      if (this.$route.meta.action == "store") {
        api
          .post(_.config.api, _.model)
          .then(() => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                icon: "fas fa-check-circle",
                title: "Create Success",
              },
            });
          })
          .catch((e) => {
            this.responses.message = e.response.data.message;
          });
      } else {
        delete _.model.id;
        delete _.model.created_at;
        delete _.model.updated_at;
        _.model.photo = '-'
        api
          .put(_.config.api + "/" + _.$route.params.id, _.model)
          .then(() => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                icon: "fas fa-check-circle",
                title: "Edit Success",
              },
            });
          })
          .catch((e) => {
            this.responses.message = e.response.data.message;
          });
      }
    },
    clickref() {
      this.profile_picture = this.$refs.file.$el.files[0];
      let reader = new FileReader();
      // eslint-disable-next-line no-unused-vars
      reader.onload = (e) => {
        this.$refs.image_tmb.src = reader.result;
      };
      reader.readAsDataURL(this.profile_picture);
      let _ = this;
      let formData = new FormData();
      let date = new Date();
      formData.append("file", this.$refs.file.$el.files[0]);
      formData.append("description", "category_armada");
      formData.append("title", "category_armada" + date);
      formData.append("status", "active");
      api
        .post(_.config.upload, formData)
        .then((res) => {
          _.model.photo = res.data.data.file;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
        });
      this.$forceUpdate();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-if="responses.message"
              variant="danger"
              class="mb-0"
              show
              dismissible
            >
              <p class="mb-0">{{ responses.message }}</p>
            </b-alert>
            <div class="row">
              <!-- <div class="col-md-2 text-center">
                <div class="p-4">
                  <b-img
                    ref="image_tmb"
                    :src="
                      profile_picture
                        ? profile_picture
                        : 'https://via.placeholder.com/200.png?text=No+Image'
                    "
                    rounded="circle"
                    alt="100x100"
                    width="100"
                    height="100"
                  ></b-img>
                  <p class="fs-6 text-danger" v-if="show == true">
                    The Photo is required
                  </p>
                  <b-button
                    variant="primary"
                    class="w-70 text-center mt-2"
                    @click="$refs.file.$el.click()"
                    >Upload Photo</b-button
                  >
                  <b-form-file
                    ref="file"
                    @input="clickref"
                    accept=".jpg, .png, .gif"
                    :hidden="true"
                    plain
                  />
                </div>
              </div> -->

              <div class="col-md-6 mt-4">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <b-form
                    @submit.prevent="handleSubmit(save)"
                    class="form-horizontal"
                  >
                    <ValidationProvider
                      name="Name"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-form-group
                        id="input-group-2"
                        label="Nama"
                        label-for="input-2"
                      >
                        <b-form-input
                          id="input-2"
                          v-model="model.name"
                          type="text"
                        ></b-form-input>
                        <p class="fs-6 text-danger">
                          {{ errors[0] }}
                        </p>
                      </b-form-group>
                    </ValidationProvider>

                    <ValidationProvider
                      name="Status"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-form-group
                        id="input-group-2"
                        label="Status"
                        label-for="input-2"
                      >
                        <b-form-radio-group
                          id="radio-group-1"
                          v-model="model.status"
                          :options="status"
                          name="radio-options"
                        ></b-form-radio-group>
                        <p class="fs-6 text-danger">
                          {{ errors[0] }}
                        </p>
                      </b-form-group>
                    </ValidationProvider>

                    <div class="form-group row">
                      <div class="col-sm-4">
                        <b-button
                          type="button"
                          to="/industri"
                          variant="light"
                          class="w-100"
                          >Kembali</b-button
                        >
                      </div>

                      <div class="col-sm-3">
                        <b-button type="submit" variant="primary" class="w-100"
                          >Simpan</b-button
                        >
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lanng="scss" scoped>
.form-control {
  border: 1.5px solid #ced4da !important;
}
</style>
